import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';

import UtahPhysicianImage from '../../assets/images/publication-banners/utah-physician.jpg';

const UtahPhysician = (props) => (
  <Layout>
    <Helmet>
      <title>Utah Physician - Mills Publishing Inc.</title>
      <meta
        name="description"
        content="Advertising and issue information for the Utah Physician magazine. Please contact Mills Publishing for availability and rates."
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Utah Physician</h1>
          </header>
          <span className="image main">
            <img src={UtahPhysicianImage} alt="" />
          </span>
          <p className="box">
            Utah Physician is the premier trade journal for physicians and other
            medical professionals in Utah. As the official publication of the
            Utah Medical Association since 1953, Utah Physician reflects the
            industry’s highest standards and reinforces the organization’s
            sterling professional image.
            <br />
            <br />
            The bi-monthly issues are as follows: February/March, April/May,
            June/July, August/September, October/November, December/January.
            Mailed out, the first week of the first month of the issue.
          </p>
          <ul className="actions">
            <li>
              <a href="https://www.utahmed.org" className="button special">
                <i className="icon fa-globe"> </i>Utah Physician Website
              </a>
            </li>
          </ul>
          <h2>Utah Physician {new Date().getFullYear()} Information</h2>
          <section className="box">
            <h3>Bi-Monthly</h3>
            February/March
            <br />
            April/May
            <br />
            June/July
            <br />
            August/September
            <br />
            October/November
            <br />
            December/January
            <br />
            <br />
            <strong>Circulation</strong>: 4,500 per issue.
            <br />
            <strong>File Ready</strong>: 25th of the month prior to
            publication/issue.
            <br />
            <em>
              Note: All dates falling on weekends will be moved to the following
              Monday.
            </em>
          </section>
        </div>
      </section>
    </div>
  </Layout>
);

export default UtahPhysician;
